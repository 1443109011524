import { useEffect, useState } from "react";
import { Alert, Badge, Button, Descriptions, Form, Modal } from "antd";
import CardBox from "../../components/CardBox";
import LoadingComponent from "../../components/Loading";
import { apiRequest, APIs } from "../../services/api/apiConfig";
import { ComponentType, SUCCESS_FAILED, USER_TYPE } from "../../constants";
import { UserDetails } from "../../constants/type";
import { DataTableColumnRender, ErrorMessageHandler } from "../../utils/Common";
import { WarningOutlined } from "@ant-design/icons";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";

function MyAccount() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<UserDetails>();
    const [visible, setVisible] = useState<boolean>(false);
    const [resetPwdForm] = Form.useForm();

    useEffect(() => {
        setIsLoading(true);

        apiRequest(APIs.GET_MY_PROFILE_DATA, {})
            .then((res) => {
                setData(res as UserDetails);
            })
            .catch((err) => ErrorMessageHandler("my account data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            .catch((err) => console.log("Failed to get my profile data: ", err))
            .finally(() => setIsLoading(false));
    }, []);

    const onResetPwd = (values: any) => {
        setIsLoading(true);
        apiRequest(APIs.RESET_MY_PASSWORD, {
            password: values.password
        })
            .then((res) => {
                ErrorMessageHandler("User password", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setVisible(false);
                resetPwdForm.resetFields();
            })
            .catch((err) => ErrorMessageHandler("user password", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    return isLoading ? (
        <div className="loading-container">
            <LoadingComponent tip="Loading..." />
        </div>
    ) : (
        <>
            <CardBox title={"My Profile"}>
                <Descriptions bordered column={1} labelStyle={{ width: "250px" }}>
                    <Descriptions.Item label="Username">{data?.username ? data.username : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Email">{data?.email ? data.email : "-"}</Descriptions.Item>
                    <Descriptions.Item label="First Name">{data?.first_name ? data.first_name : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Last Name">{data?.last_name ? data.last_name : "-"}</Descriptions.Item>
                    <Descriptions.Item label="User Type">{data?.type ? USER_TYPE[data.type] : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {data?.active ? (
                            <Badge status="processing" text="Active" color="#49aa19" />
                        ) : (
                            <Badge status="error" text="Inactive" color="#f00f00" />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nick Name">{data?.nickname ? data.nickname : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Description">{data?.description ? data.description : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Password">
                        <Button onClick={() => setVisible(true)}>Change Password</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Last IP Address">{data?.last_ip_address ? data.last_ip_address : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Last Logged In">
                        {data?.last_login_utc ? DataTableColumnRender.DateTime(data.last_login_utc) : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created By">{data?.created_by ? data?.created_by : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Created At">
                        {data?.created_date_utc ? DataTableColumnRender.DateTime(data.created_date_utc) : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Modified By">{data?.modified_by ? data?.modified_by : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Modified At">
                        {data?.modified_date_utc ? DataTableColumnRender.DateTime(data.modified_date_utc) : "-"}
                    </Descriptions.Item>
                </Descriptions>
            </CardBox>
            <Modal
                open={visible}
                title="Change Password"
                onCancel={() => {
                    setVisible(false);
                    resetPwdForm.resetFields();
                }}
                onOk={() => {
                    resetPwdForm
                        .validateFields()
                        .then((values) => {
                            onResetPwd(values);
                            setVisible(false);
                        })
                        .catch((info) => {
                            console.log("Validate Failed:", info);
                        });
                }}
            >
                <Form form={resetPwdForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                    <div style={{ marginBottom: 20 }}>
                        <Alert
                            description="Password must contain at least 8 characters including at least 1 uppercase letter, 1 lowercase letter and 1 number."
                            type="error"
                            showIcon
                            icon={<WarningOutlined />}
                        />
                    </div>
                    <FormComponent
                        label="New Password"
                        name={"password"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length < 1) {
                                            return Promise.reject(new Error(REQUIRED_FIELD));
                                        } else if (!(/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /[0-9]+/.test(value)) || value.length < 8) {
                                            return Promise.reject(new Error("Invalid password."));
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ],
                        }}
                    />
                    <FormComponent
                        label="Confirm Password"
                        name={"confirmPassword"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Password is not matched, please try again"));
                                    },
                                }),
                            ],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default MyAccount;
