import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Form, Modal, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardBox from "../../components/CardBox";
import FlexiDataTable from "../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, STATUS_TYPE, SUCCESS_FAILED, USER_TYPE } from "../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, ClientDetails } from "../../constants/type";
import { apiRequest } from "../../services/api/apiConfig";
import { APIs } from "../../services/api/apis";
import { DTColProps, ErrorMessageHandler } from "../../utils/Common";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";

const ClientManagementPage = () => {
    const [data, setData] = useState<ClientDetails[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [, setResetPasswordModal] = useState<any>({});
    const [resetPwdForm] = Form.useForm();

    let navigate = useNavigate();

    const getClientMgmtList = () => {
        setIsLoading(true);
        apiRequest(APIs.CLIENT_GET, {})
            .then((res: any) => {
                if (res) {
                    setData(res as ClientDetails[]);
                }
            })
            .catch((err) => { })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getClientMgmtList();
    }, []);

    const columns = [
        DTColProps.Middle({
            title: "Client Name",
            dataIndex: "clientName",
            key: "clientName",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.Middle({
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.Middle({
            title: "Domain",
            dataIndex: "domain",
            key: "domain",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.XSmall({
            title: "Status",
            dataIndex: "active",
            key: "active",
            render: (active: boolean, record: ClientDetails) => {
                switch (active) {
                    case true:
                        return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "30px" }} />;
                    case false:
                        return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "30px" }} />;
                }
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: Object.keys(STATUS_TYPE)
                        .map((x) => parseInt(x))
                        .map((x) => ({ text: STATUS_TYPE[x], value: x })),
                },
            },
        }),

        DTColProps.Small({
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.DateTime({
            title: "Created At",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            sorter: (a: any, b: any) => a.createdDateUtc.localeCompare(b.createdDateUtc),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
        }),

        DTColProps.Small({
            title: "Modified By",
            dataIndex: "modifiedBy",
            key: "modifiedBy",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.DateTime({
            title: "Modified At",
            dataIndex: "modifiedDateUtc",
            key: "modifiedDateUtc",
            sorter: (a: any, b: any) => a.modifiedDateUtc.localeCompare(b.modifiedDateUtc),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: { text: "Create Client" },
        edit: true,
        delete: true
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/clients/create");
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/clients/edit", { state: FormData });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(`${APIs.CLIENT_DELETE}`, { id: FormData.id }, "POST")
                    .then((res) => {
                        ErrorMessageHandler("The client record", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setIsLoading(false);
                        getClientMgmtList();
                    })
                    .catch((error) => {
                        ErrorMessageHandler("client. Client needs to be deactivated first before deleting.", SUCCESS_FAILED.FAILED_DELETE_DATA, error);
                        setIsLoading(false);
                    });
                break;
        }
    };

    return (
        <>
            <CardBox title={"Client Management"}>
                <FlexiDataTable rowKeyProperty="id" title="" columns={columns} options={options} dataSource={data || []} callback={componentCallback} loading={isLoading} />
            </CardBox>
        </>
    );
};

export default ClientManagementPage;
