import { ProfileProps } from "../../constants/type";
import { parseJwt } from "../../utils/Common";
import { removeToken, getToken, getProfile, removeProfile, } from "../localStorage";

export interface UserInfoProps {
    username: string;
    type: string;
    //userid: string;
    uuid: string;
    email: string;
}

export const userLogout = async () => {
    removeToken();
    removeProfile();
};

export const userInfo = (): UserInfoProps | undefined => {
    const accessTokenStr = getToken();
    if (accessTokenStr) {
        try {
            let userObj: UserInfoProps = parseJwt(accessTokenStr);
            const profileObj: ProfileProps | undefined = getProfile();
            if (profileObj) {
                userObj.username = profileObj.firstName;
                userObj.type = profileObj.roles;
            }

            return userObj;
        } catch (error) { }
    }
    return undefined;
};

