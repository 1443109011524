import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Form, Modal, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardBox from "../../components/CardBox";
import FlexiDataTable from "../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, STATUS_TYPE, SUCCESS_FAILED, USER_TYPE } from "../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, UserDetails } from "../../constants/type";
import { apiRequest } from "../../services/api/apiConfig";
import { APIs } from "../../services/api/apis";
import { DTColProps, ErrorMessageHandler } from "../../utils/Common";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";

const UserManagementPage = () => {
    const [data, setData] = useState<UserDetails[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [, setResetPasswordModal] = useState<any>({});
    const [resetPwdForm] = Form.useForm();

    let navigate = useNavigate();

    const getUserMgmtList = () => {
        setIsLoading(true);
        apiRequest(APIs.USER_GET_PROFILE, {})
            .then((res: any) => {
                if (res) {
                    setData(res as UserDetails[]);
                }
            })
            .catch((err) => { })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getUserMgmtList();
    }, []);

    const columns = [
        DTColProps.Middle({
            title: (
                <span key={"urnm-tlps"}>
                    Username
                    <Tooltip title={"User's login name"} key={"urnm-tlps-cl"}>
                        <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Tooltip>
                </span>
            ),
            dataIndex: "username",
            key: "username",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.Small({
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.Small({
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.XSmall({
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text: number, record: any) => USER_TYPE[text] ?? "",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: Object.keys(USER_TYPE)
                        .map((x) => parseInt(x))
                        .map((x) => ({ text: USER_TYPE[x], value: x })),
                },
            },
        }),

        DTColProps.XSmall({
            title: "Status",
            dataIndex: "active",
            key: "active",
            render: (active: boolean, record: UserDetails) => {
                switch (active) {
                    case true:
                        return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "30px" }} />;
                    case false:
                        return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "30px" }} />;
                }
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: Object.keys(STATUS_TYPE)
                        .map((x) => parseInt(x))
                        .map((x) => ({ text: STATUS_TYPE[x], value: x })),
                },
            },
        }),

        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        
        DTColProps.DateTime({
            title: "Last Login At",
            dataIndex: "last_login_utc",
            key: "last_login_utc",
            sorter: (a: any, b: any) => a.last_login_utc.localeCompare(b.last_login_utc),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
        }),

    ];

    const options: FlexiDataTableOptionsProps = {
        add: { text: "Create User" },
        edit: true,
        delete: true,
        rowExtra: [{ text: "Reset Password", value: "reset_password" }],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/users/create");
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/users/edit", { state: FormData });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(`${APIs.USER_DELETE_PROFILE}`, { selected_uuid: FormData.uuid }, "POST")
                    .then((res) => {
                        ErrorMessageHandler("The user record", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setIsLoading(false);
                        getUserMgmtList();
                    })
                    .catch((error) => {
                        ErrorMessageHandler("user. User needs to be deactivated first before deleting.", SUCCESS_FAILED.FAILED_DELETE_DATA, error);
                        setIsLoading(false);
                    });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "reset_password") {
                    setShowModal(true);
                    setResetPasswordModal(FormData.data);
                    resetPwdForm.setFieldsValue({ uuid: FormData.data["uuid"], password: "" });
                }
                break;
        }
    };

    const resetUserPassword = (selected_uuid: string) => {
        apiRequest(`${APIs.USER_RESET_PASSWORD}`, { selected_uuid })
            .then((res) => {
                ErrorMessageHandler("Account password reset", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setShowModal(false);
                resetPwdForm.resetFields();
                getUserMgmtList();
            })
            .catch((error) => {
                ErrorMessageHandler("account password reset", SUCCESS_FAILED.FAILED_UPDATE_DATA, error);
                setShowModal(false);
            });
    };

    return (
        <>
            <CardBox title={"User Management"}>
                <FlexiDataTable rowKeyProperty="uuid" title="" columns={columns} options={options} dataSource={(data || []).filter((x) => x.uuid !== "")} callback={componentCallback} loading={isLoading} />
            </CardBox>
            <Modal
                open={showModal}
                title="Change Password"
                onCancel={() => {
                    setShowModal(false);
                    resetPwdForm.resetFields();
                }}
                onOk={() => {
                    resetPwdForm
                        .validateFields()
                        .then((values) => resetUserPassword(values.uuid))
                        .catch((err) => { });
                }}
            >
                <Form form={resetPwdForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                    <div style={{ marginBottom: 20 }}>
                        <Alert
                            description="Confirm to reset password ?"
                            type="warning"
                            showIcon
                            icon={<WarningOutlined />}
                        />
                    </div>
                    <FormComponent label={""} name={"uuid"} extra={{ type: ComponentType.hidden, value: "" }} />
                </Form>
            </Modal>
        </>
    );
};

export default UserManagementPage;
