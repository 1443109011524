import { configureStore } from "@reduxjs/toolkit";
import systemReducer from "./reducers/system";

export const store = configureStore({
    reducer: {
        system: systemReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
