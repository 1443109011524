import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { Form, Select, Input, Switch, Checkbox, Tree, DatePicker, Transfer, TimePicker, Radio } from "antd";
import { FormItemProps, Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { DefaultOptionType } from "antd/lib/select";
import { ColumnTitle } from "antd/lib/table/interface";
import { DataNode } from "antd/lib/tree";
import { CSSProperties, useEffect, useState } from "react";
import { ComponentType } from "../../constants";
import { OptionType } from "../../constants/type";
import LoadingComponent from "../Loading";
import "./FormComponent.less";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export interface FormComponentProps {
    // label: string | ColumnTitle<any>;
    label: string;
    name: NamePath;
    extra: ExtraProps;
    isLoading?: boolean;
}

export interface ExtraProps {
    type: string;
    rules?: Rule[];
    disabled?: boolean;
    itemProps?: FormItemProps<any>;
    inputProps?: any;
    value: any;
    dateFormat?: string;
    tooltip?: string;
}

export interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

export const hiddenWrapperProps = {
    className: "hidden-wrapper-form-item",
    label: "",
};

const FormComponent = (props: FormComponentProps) => {
    const { label, name, extra } = props;
    let defaultItemProps = {}; //{ labelCol: { flex: 8 }, wrapperCol: { flex: 16 } };
    switch (extra.type) {
        case ComponentType.labelOnly:
        case ComponentType.text:
        case ComponentType.number:
        case ComponentType.hidden:
        case ComponentType.password:
            const inputProps = {
                type: ComponentType.labelOnly === extra.type ? "text" : extra.type,
                autoComplete: "off",
                disabled: extra.disabled || ComponentType.labelOnly === extra.type,
                value: extra.value !== "" ? extra.value : extra.type === "number" ? 0 : "",
            };
            return (
                <Form.Item
                    hidden={extra.type === "hidden"}
                    className="form-text"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    {props.isLoading ? (
                        <LoadingComponent />
                    ) : ComponentType.labelOnly === extra.type ? (
                        <Input {...Object.assign({}, inputProps, extra.inputProps)} />
                    ) : ComponentType.password === extra.type ? (
                        <Input.Password
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            {...Object.assign({}, inputProps, extra.inputProps)}
                        />
                    ) : (
                        <Input {...Object.assign({}, inputProps, extra.inputProps)} />
                    )}
                </Form.Item>
            );
        case ComponentType.textarea:
            return (
                <Form.Item
                    className="form-text"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <TextArea disabled={extra.disabled || false} {...Object.assign({}, { rows: 4 }, extra.inputProps)} />
                </Form.Item>
            );
        case ComponentType.dropdown:
            return (
                <Form.Item
                    className="form-select"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={"Please select " + (label as string).toString().toLowerCase()}
                        filterOption={(input, option: DefaultOptionType) =>
                            (option.children || "").toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                        }
                        disabled={extra.disabled || false}
                        allowClear={true}
                        {...extra.inputProps}
                    >
                        {extra.value &&
                            extra.value.map((x: OptionType) => (
                                <Option key={"s-o-" + x.value} value={x.value}>
                                    {x.text}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            );
        case ComponentType.switch:
            return (
                <Form.Item className="form-switch" label={label} name={name} rules={extra.rules} valuePropName="checked">
                    <Switch checkedChildren={extra.value[1]} unCheckedChildren={extra.value[0]} defaultChecked={false} />
                </Form.Item>
            );
        case ComponentType.checkbox:
            return (
                <Form.Item
                    className="form-checkbox"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                    valuePropName="checked"
                >
                    <Checkbox>{extra.value}</Checkbox>
                </Form.Item>
            );
        case ComponentType.radio:
            return (
                <Form.Item
                    className="form-radio"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Radio.Group>
                        {extra.value &&
                            extra.value.map((x: OptionType) => (
                                <Radio key={`f-r-g-${x.value}`} value={x.value}>
                                    {x.text}
                                </Radio>
                            ))}
                    </Radio.Group>
                </Form.Item>
            );
        case ComponentType.checkboxgroup:
            return (
                <Form.Item
                    className="form-checkboxgroup"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Checkbox.Group options={extra.value.map((x: OptionType) => ({ label: x.text, value: x.value }))} />
                </Form.Item>
            );
        case ComponentType.time:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <TimePicker style={{ width: "100%" }} format={"HH:mm:ss"} />
                </Form.Item>
            );
        case ComponentType.date:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <DatePicker
                        style={{ width: "100%" }}
                        {...Object.assign({ format: (value: any) => value.format(extra.dateFormat || "YYYY-MM-DD HH:mm:ss") }, extra.inputProps || {})}
                    />
                </Form.Item>
            );
        case ComponentType.daterange:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <RangePicker format={(value) => value.format(extra.dateFormat || "YYYY-MM-DD HH:mm:ss")} {...(extra.inputProps || {})} />
                </Form.Item>
            );
        case ComponentType.transfer:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <Transfer {...(extra.inputProps || {})} />
                </Form.Item>
            );
        case ComponentType.tree:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <TreeInput data={extra.value} />
                </Form.Item>
            );
        case ComponentType.numberrange:
            const componentProps = {
                type: "number",
                autoComplete: "off",
                disabled: extra.disabled,
                value: extra.value !== "" ? extra.value : 0,
            };

            return (
                <Form.Item label={label}>
                    <Input.Group compact>
                        <Form.Item name={[name as string, "from"]} noStyle rules={extra.rules}>
                            <Input
                                {...Object.assign({}, componentProps, extra.inputProps)}
                                style={{ width: "40%", borderRight: "none", textAlign: "right" }}
                                placeholder="Minimum"
                            />
                        </Form.Item>
                        <Input
                            style={{
                                width: "20%",
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: "none",
                                textAlign: "center",
                                background: "transparent",
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item name={[name as string, "to"]} noStyle rules={extra.rules}>
                            <Input
                                {...Object.assign({}, componentProps, extra.inputProps)}
                                style={{ width: "40%", borderLeft: "none", textAlign: "right" }}
                                placeholder="Maximum"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            );
        // case ComponentType.upload:
        //     return (
        //         <Form.Item
        //             label={label}
        //             name={name}
        //             rules={extra.rules}
        //             {...Object.assign(defaultItemProps, extra.itemProps || {})}
        //             valuePropName="fileList"
        //         >
        //             <Upload listType="picture-card" {...(extra.inputProps || {})}>
        //                 <div>
        //                     <PlusOutlined />
        //                     <div style={{ marginTop: 8 }}>Upload</div>
        //                 </div>
        //             </Upload>
        //         </Form.Item>
        //     );
        //     break;
        default:
            return <></>;
    }
};

interface TreeItemProps { }

export interface TreeDataProps {
    data: DataNode[];
    value?: string[];
    onChange?: (value: string[]) => void;
}

export const TreeInput = (props: TreeDataProps) => {
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(props.value as string[]);
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue);
        props.onChange && props.onChange(checkedKeysValue);
    };

    useEffect(() => {
        setCheckedKeys(props.value as string[]);
    }, [props.value]);

    return (
        <div className="form-input-tree">
            <Tree
                checkable
                selectable={false}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={(selectedKeysValue: React.Key[], info: any) => setSelectedKeys(selectedKeysValue)}
                selectedKeys={selectedKeys}
                treeData={props.data}
            />
        </div>
    );
};

export enum FormInputType {
    Text = 1,
    Password = 2,
    Hidden = 3,
    Dropdown = 4,
    DateRange = 5,
    Date = 6,
    Time = 7,
    Transfer = 8,
    Number = 9,
}

const FORM_INPUT_TYPE: { [key: number]: string } = {
    1: "text",
    2: "password",
    3: "hidden",
    4: "dropdown",
    5: "daterange",
    6: "date",
    7: "time",
    8: "transfer",
    9: "number",
};

export interface FormInputProps {
    type?: FormInputType | undefined;
    label?: string | React.ReactNode;
    name: string;
    placeHolder?: string;
    value: any;
    styles?: CSSProperties;
    className?: string;
    rules?: Rule[];
    itemProps?: FormItemProps;
    inputProps?: any;
    extra?: any;
}

const FormInput = (props: FormInputProps): JSX.Element => {
    switch (props.type) {
        case FormInputType.Text:
        case FormInputType.Number:
        case FormInputType.Password:
        case FormInputType.Hidden:
            const inputProps = {
                size: "large",
                wrapperClassName: "form-input-wrapper",
                className: ["form-input-c", props.className].join(" "),
                style: props.styles,
                placeholder: props.placeHolder,
                addonBefore: <span className="form-input-label">{props.label}</span>,
                type: FORM_INPUT_TYPE[props.type],
                autoComplete: "off",
                value: props.type === FormInputType.Number ? (props.value as number) : (props.value as string),
            };
            return (
                <Form.Item
                    hidden={props.type === FormInputType.Hidden}
                    className="form-input-text"
                    name={props.name}
                    rules={props.rules}
                    {...Object.assign({}, props.itemProps)}
                >
                    {FormInputType.Password === props.type ? (
                        <Input.Password
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            {...Object.assign({}, inputProps, props.inputProps)}
                        />
                    ) : (
                        <Input {...Object.assign({}, inputProps, props.inputProps)} />
                    )}
                </Form.Item>
            );
    }

    return <></>;
};

FormInput.defaultProps = {
    type: "text",
    label: "",
    placeHolder: "",
    value: "",
    styles: {},
    className: "",
    rules: [],
    itemProps: {},
    inputProps: {},
};

export { FormComponent, FormInput };
