import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Form, Button, message, Col, Row } from "antd";

import { TeamOutlined } from "@ant-design/icons";
import SitePageHeader from "../../components/PageHeader";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";
import { UserDetails } from "../../constants/type";
import LoadingComponent from "../../components/Loading";
import { apiRequest } from "../../services/api/apiConfig";
import { APIs } from "../../services/api/apis";
import { ComponentType, SUCCESS_FAILED, USER_TYPE } from "../../constants";
import { ErrorMessageHandler } from "../../utils/Common";
import { ToOptionTypeList } from "../../utils/array";

const UserCreatePage = () => {
    let navigate = useNavigate();

    const [data, setData] = useState<UserDetails[]>([]);
    const [userForm] = Form.useForm();
    const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        try {
            setIsLoadingState(true);

            apiRequest(APIs.USER_CREATE_PROFILE, { ...values })
                .then(() => {
                    ErrorMessageHandler("New user", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/users");
                })
                .catch((err: any) => {
                    ErrorMessageHandler("new user", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                })
                .finally(() => setIsLoadingState(false));
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    const onValueChange = (changedComponent: any, allvalue: any) => {
        Object.keys(changedComponent).map((x) => {
            const value = changedComponent[x];

            switch (x) {
                case "uuid":
                    let u = ((data as UserDetails[]) || []).find((x) => x.uuid === value);
                    if (u !== undefined) {
                        userForm.setFieldsValue({ ...allvalue, firstName: u.first_name, lastName: u.last_name, email: u.email });
                    }
                    break;
            }

            return false;
        });
    };

    useEffect(() => {
        apiRequest(APIs.USER_GET_PROFILE, {})
            .then((response: any) => {
                let userDetail = response as UserDetails[];
                setData(userDetail);
            })
            .catch((err: any) => console.log(`Error: ${err}`))
    }, []);

    return (
        <SitePageHeader
            title={"Create User Account"}
            routes={[
                { path: "/users", breadcrumbName: "Users Management", icon: <TeamOutlined /> },
                { path: "", breadcrumbName: "Create User Account" },
            ]}
            onBack={() => navigate("/users")}
        >
            {isLoadingState ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={userForm} layout="horizontal" initialValues={{}} onValuesChange={onValueChange} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Username"
                                name="username"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Password"
                                name={"password"}
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                    rules: [
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || value.length < 1) {
                                                    return Promise.reject(new Error(REQUIRED_FIELD));
                                                } else if (!(/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /[0-9]+/.test(value)) || value.length < 8) {
                                                    return Promise.reject(new Error("Invalid password."));
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="First Name"
                                name="first_name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last Name"
                                name="last_name"
                                extra={{
                                    type: ComponentType.text,
                                    value: ""
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="User Type"
                                name="type"
                                extra={{
                                    type: ComponentType.dropdown,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                    value: ToOptionTypeList(USER_TYPE),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Nickname"
                                name="nickname"
                                extra={{
                                    type: ComponentType.text,
                                    value: ""
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: ""
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default UserCreatePage;
