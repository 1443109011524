import { Table, Row, Col, Dropdown, Menu, Popconfirm, Button, Tag } from "antd";
import React, { useEffect, useState } from "react";
import FlexiFilter from "../FlexiFilter";
import { DeleteOutlined, EditOutlined, EyeOutlined, MenuOutlined } from "@ant-design/icons";
import { DTColProps } from "../../utils/Common";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import moment from "moment";
import { cloneDeep } from "lodash";
import {
    CustomPaginationProps,
    FlexiColumnFilterProps,
    FlexiDataColumnProps,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    IconKeyValuePair,
} from "../../constants/type";
import { CALLBACK_KEY, ComponentType } from "../../constants";
import "./FlexiDataTable.less";
import { MenuProps } from "rc-menu/lib/Menu";

export interface FlexiDataTableProps {
    title: string | React.ReactNode;
    rowKeyProperty: string;
    options: FlexiDataTableOptionsProps;
    filterInitialValue?: any;
    callback: FlexiDataTableCallbackProps;
    columns: FlexiDataColumnProps[];
    dataSource: any;
    loading: boolean;
    pagination?: CustomPaginationProps | boolean;
    serverSide?: boolean;
    bordered?: boolean;
    size?: any;
    exporting?: boolean;
}

export interface ExtraPagination extends CustomPaginationProps {
    pageSizeOptions?: number[];
}

const initialDataTable = {
    bordered: false,
    serverSide: false,
    options: {
        add: false,
        edit: false,
        delete: false,
        separateActionButton: false,
        enableFilter: true,
        autoFilter: true,
        serverFiltering: false,
        enableRowSelection: false,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
        },
        refresh: false,
        resync: false,
        extraButtons: [],
    },
};

const getKeyValueObject = (rowKey: string, list: any[]) => {
    let rowKeyData: { [key: string]: any } = {};
    list.map((x: any) => {
        rowKeyData[x[rowKey].toString()] = x;
    });
    return rowKeyData;
};

const FlexiDataTable = (props: FlexiDataTableProps) => {
    const [filteredData, setFilteredData] = useState<any>(props.filterInitialValue || {});
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
        props.options.enableRowSelection ? props.options.rowSelectionData?.selectedRowKeys || [] : []
    );
    const [selectionData, setSelectionData] = useState<{ [key: string]: any }>(getKeyValueObject(props.rowKeyProperty, props.dataSource));
    const [tableOpt, setTableOpt] = useState<FlexiDataTableOptionsProps>(Object.assign({ ...initialDataTable.options }, props.options));
    const [tableCol, setTableCol] = useState<FlexiDataColumnProps[]>([...props.columns]);
    const [exportingState, setExportingState] = useState<boolean>(props.exporting || false);

    useEffect(() => {
        setSelectionData(getKeyValueObject(props.rowKeyProperty, props.dataSource));
        if (props.options.enableRowSelection) {
            setSelectedRowKeys(props.options.rowSelectionData?.selectedRowKeys || []);
        }
    }, [props.dataSource]);

    useEffect(() => {
        setTableOpt(Object.assign({ ...initialDataTable.options }, props.options));
    }, [props.options]);

    useEffect(() => {
        setTableCol(props.columns);
    }, [props.columns]);

    useEffect(() => {
        setExportingState(props.exporting || false);
    }, [props.exporting]);

    const getActualDataSource = (dataSource: any[]) => {
        const ori_data = cloneDeep(dataSource);
        let filter_values = tableOpt.serverFiltering ? {} : filteredData;
        let filter_keys = Object.keys(filter_values).filter(
            (x) => filter_values[x] !== undefined && (typeof filter_values[x] === "string" ? filter_values[x].length > 0 : true)
        );
        let filter_callback: { [key: string]: Function } = {};
        tableCol.map((x) => {
            if (x.options?.filter && x.options?.filter?.callback && x.key !== undefined) filter_callback[x.key] = x.options?.filter?.callback;
        });
        return ori_data.filter((x: any) => {
            let isValid = true;
            filter_keys.map((k: string) => {
                if (x.children) {
                    x.children = x.children.filter((y: string) => rowChecking(filter_callback, filter_values, k, y));
                    if (x.children.length < 1) isValid = false;
                } else {
                    if (!rowChecking(filter_callback, filter_values, k, x)) isValid = false;
                }
            });
            return isValid;
        });
    };

    const rowChecking = (filter_callback: { [key: string]: Function }, filter_values: { [key: string]: any }, k: string, x: any) => {
        if (filter_callback[k] !== undefined) {
            if (!filter_callback[k](filter_values[k], x)) {
                return false;
            }
        } else {
            switch (typeof x[k]) {
                case "string":
                    if (typeof filter_values[k] !== "string" && filter_values[k].length === 2) {
                        // for daterange checking
                        try {
                            let r_date = moment(x[k]);
                            if (filter_values[k][0] !== undefined && filter_values[k][0] > r_date) {
                                return false;
                            }
                            if (filter_values[k][1] !== undefined && filter_values[k][1] < r_date) {
                                return false;
                            }
                        } catch { }
                    } else {
                        if (x[k].trim().toLowerCase().indexOf(filter_values[k].trim().toString().toLowerCase()) === -1) {
                            return false;
                        }
                    }
                    break;
                case "number":
                    if (x[k] !== Number(filter_values[k])) {
                        return false;
                    }
                    break;
                case "boolean":
                    if (x[k] !== Boolean(filter_values[k])) {
                        return false;
                    }
                    break;
                case "object":
                    if (!x[k].includes(filter_values[k])) {
                        return false;
                    }
                    break;
                default:
                    break;
            }
        }
        return true;
    };

    const prepareColumnOption = (columns: FlexiDataColumnProps[], options: FlexiDataTableOptionsProps): FlexiDataColumnProps[] => {
        let _columns = [...columns];
        // let _columns = cloneDeep(columns); //clonedeep cause javascript performance issue

        //#region exclude visible: false
        _columns = _columns.filter((x) => (x.options !== undefined && x.options.visible !== undefined ? x.options.visible : true));
        //#endregion

        //#region markupColumn
        _columns.map((x) => {
            if (x.options) {
                if (x.options.filter) {
                    x.options.filter = Object.assign({ type: ComponentType.text } as FlexiColumnFilterProps, x.options.filter);
                }
            }
            return x;
        });
        //#endregion

        //#region set action column
        if (options.view || options.delete || options.edit || options.rowExtra) {
            let fullSize = 100;
            if (options.separateActionButton) {
                fullSize = 150;
                if (!options.view && fullSize > 100) fullSize -= 20;
                if (!options.delete && fullSize > 100) fullSize -= 20;
                if (!options.edit && fullSize > 100) fullSize -= 20;
                if (!options.rowExtra && fullSize > 100) fullSize -= 20;
            }
            _columns.push(
                DTColProps.Action({
                    width: `${fullSize}px`,
                    render: (text: any, record: any, index: number) => {
                        let items: MenuProps['items'] = [];
                        let view_button: React.ReactNode = undefined;
                        if (options.view) {
                            view_button = (
                                <Button
                                    key={`r-${record.id || index}-v`}
                                    type="text"
                                    icon={<EyeOutlined style={{ fontSize: "18px" }} />}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.callback(CALLBACK_KEY.VIEW_RECORD, record);
                                    }}
                                />
                            );
                            if (typeof options.view === "function") {
                                view_button = options.view(record, view_button);
                            }
                        }
                        if (options.edit) {
                            const edit_button = options.separateActionButton ? (
                                <Button
                                    key={`r-${record.id || index}-e`}
                                    type="text"
                                    icon={<EditOutlined style={{ fontSize: "18px" }} />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.callback(CALLBACK_KEY.DO_EDIT, record);
                                    }}
                                />
                            ) : (
                                <Button
                                    type="text"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.callback(CALLBACK_KEY.DO_EDIT, record);
                                    }}
                                >
                                    Edit
                                </Button>
                            );
                            if (typeof options.edit === "function") {
                                let editOption = options.edit(record, {
                                    key: `r-${record.id || index}-e`,
                                    label: edit_button,
                                } as ItemType);
                                if (editOption) items.push(editOption);
                            } else {
                                items.push({
                                    key: `r-${record.id || index}-e`,
                                    label: edit_button,
                                } as ItemType);
                            }
                        }
                        if (options.delete) {
                            const delete_button = (
                                <Popconfirm
                                    key={`r-${record.id || index}-d`}
                                    placement="topRight"
                                    title={"Confirm to delete?"}
                                    icon={<DeleteOutlined size={14} />}
                                    onConfirm={() => props.callback(CALLBACK_KEY.DO_DELETE, record)}
                                    okText={"Yes"}
                                    cancelText={"No"}
                                    style={{ width: "300px" }}
                                >
                                    {options.separateActionButton ? (
                                        <Button
                                            key={`r-${record.id || index}-d-btn`}
                                            type="text"
                                            icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            type="text"
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Popconfirm>
                            );
                            if (typeof options.delete === "function") {
                                let deleteOption = options.delete(record, {
                                    key: `r-${record.id || index}-d`,
                                    label: delete_button,
                                } as ItemType);
                                if (deleteOption) items.push(deleteOption);
                            } else {
                                items.push({
                                    key: `r-${record.id || index}-d`,
                                    label: delete_button,
                                } as ItemType);
                            }
                        }
                        if (options.rowExtra !== undefined) {
                            let rowExtra_list: IconKeyValuePair[] = [];

                            if (typeof options.rowExtra === "function") {
                                rowExtra_list = options.rowExtra(record, props.callback);
                            } else {
                                rowExtra_list = options.rowExtra as IconKeyValuePair[];
                            }

                            if (rowExtra_list.length > 0) {
                                if (options.separateActionButton) {
                                    let tmpButton: ItemType[] = [];
                                    rowExtra_list.map((x: any, i: any) => {
                                        tmpButton.push({
                                            key: `r-${record.id || index}-ex-${i}`,
                                            label: x.icon ? (
                                                <Button
                                                    key={`r-${record.id || index}-ex-${i}`}
                                                    type="text"
                                                    icon={x.icon}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                    }}
                                                />
                                            ) : (
                                                <Button
                                                    key={`r-${record.id || index}-ex-${i}`}
                                                    type="text"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                    }}
                                                >
                                                    {x.text}
                                                </Button>
                                            ),
                                        } as ItemType);
                                    });

                                    if (tmpButton.length > 0) {
                                        items = tmpButton;
                                        // items.push({
                                        //     key: `r-${record.id || index}-hex`,
                                        //     label: (
                                        //         <Dropdown overlay={<Menu className="row-dropdow-panel" items={tmpButton} />} trigger={["click"]}>
                                        //             <MenuOutlined />
                                        //         </Dropdown>
                                        //     ),
                                        // } as ItemType);
                                    }
                                } else {
                                    rowExtra_list.map((x: any, i: any) => {
                                        items?.push({
                                            key: `r-${record.id || index}-ex-${i}`,
                                            label: (
                                                <Button
                                                    type="text"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                    }}
                                                >
                                                    {x.text}
                                                </Button>
                                            ),
                                        } as ItemType);
                                    });
                                }
                            }
                        }
                        return items.length > 0 || view_button ? (
                            <div className="row-action-button-container">
                                {view_button && view_button}
                                {items.length > 0 &&
                                    (options.separateActionButton ? (
                                        items.map((x: any) => {
                                            return x.label;
                                        })
                                    ) : (
                                        <Dropdown
                                            trigger={["click"]}
                                            overlayClassName="row-dropdow-panel"
                                            menu={{ items }}
                                        >
                                            <MenuOutlined />
                                        </Dropdown>
                                    ))}
                            </div>
                        ) : (
                            <></>
                        );
                    },
                })
            );
        }
        //#endregion
        return _columns;
    };

    const componentCallback = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                if (tableOpt.autoFilter) {
                    setFilteredData(data);
                }
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(data.selectedRowKeys);
                break;
            default:
                break;
        }

        props.callback && props.callback(type, data);
    };

    return (
        <div className={`flexi-datatable ${props.bordered ? "bordered" : ""}`}>
            <Row key="flexi-filter">
                <Col span={24}>
                    <FlexiFilter
                        title={props.title}
                        initialValues={props.filterInitialValue}
                        options={tableOpt}
                        columns={tableCol.filter((x) => x.options?.filter !== null)}
                        callback={componentCallback}
                        enableFilter={tableOpt.enableFilter}
                        exporting={exportingState}
                    />
                </Col>
            </Row>
            {tableOpt.enableRowSelection && selectedRowKeys !== undefined && selectedRowKeys.length > 0 && (
                <Row>
                    <Col span={24}>
                        <div className="flexi-selectedRow-Container">
                            {selectedRowKeys.map((x, index) => {
                                return (
                                    <Tag
                                        key={`flexi-sr-${x}`}
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            let tmp = [...selectedRowKeys];
                                            tmp.splice(index, 1);
                                            setSelectedRowKeys(tmp);
                                            componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, { selectedRowKeys: tmp });
                                        }}
                                    >
                                        {tableOpt.rowSelectionData?.selectedKeyRender
                                            ? tableOpt.rowSelectionData?.selectedKeyRender(x, selectionData[x])
                                            : x}
                                    </Tag>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            )}
            <Row key="flexi-table">
                <Col span={24}>
                    <Table
                        className="flexi-table"
                        size={props.size || "large"}
                        rowKey={(record) => record[props.rowKeyProperty] + ""}
                        columns={prepareColumnOption(tableCol, props.options)}
                        dataSource={getActualDataSource(props.dataSource)}
                        loading={props.loading}
                        pagination={
                            typeof props.pagination === "boolean"
                                ? false
                                : Object.assign(
                                    {
                                        showSizeChanger: true,
                                        defaultPageSize: 10,
                                    },
                                    props.pagination
                                )
                        }
                        scroll={{
                            y: window.innerHeight * 0.8,
                            x: 1500,
                        }}
                        onChange={(pagination: CustomPaginationProps, filters: any, sorter: any) => {
                            if (props.serverSide || initialDataTable.serverSide)
                                componentCallback(CALLBACK_KEY.HANDLE_PAGINATION_SORTING, { pagination: pagination, sorter: sorter });
                        }}
                        {...(tableOpt.enableRowSelection &&
                            (tableOpt.rowSelectionData?.rowSelectionType !== "checkbox"
                                ? {
                                    onRow: (record: any, rowIndex: any) => {
                                        return {
                                            onClick: (event: any) =>
                                                componentCallback &&
                                                componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, {
                                                    selectedRowKeys: record[props.rowKeyProperty],
                                                    selectedRows: record,
                                                }),
                                        };
                                    },
                                }
                                : {
                                    rowSelection: {
                                        type: "checkbox",
                                        selectedRowKeys: selectedRowKeys,
                                        onChange: (selectedKeys, selectedRows) =>
                                            componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, { selectedRowKeys: selectedKeys, selectedRows }),
                                    },
                                }))}
                        expandable={props.options.expandable}
                    />
                </Col>
            </Row>
        </div>
    );
};

FlexiDataTable.defaultProps = {
    rowKeyProperty: "Id",
    options: {
        add: false,
        edit: false,
        delete: false,
        autoFilter: true,
        serverFiltering: false,
        enableRowSelection: false,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
        },
    },
    callback: function (type: number, data: any): void {
        console.log("Function not implemented.");
    },
    serverSide: false,
};

export default FlexiDataTable;
