import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./Loading.less";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoadingComponent = (props: any) => {
    return (
        <Spin indicator={antIcon} {...props}>
            {props.children}
        </Spin>
    );
};

export default LoadingComponent;
