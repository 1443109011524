import { message } from "antd";
import moment from "moment";
import { ROLES_LABELS, SUCCESS_FAILED_MSG } from "../constants";

export const parseJwt = (token: string): undefined | any => {
    if (token && token.split(".").length === 3) {
        try {
            let base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
            // var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            // }).join(''));
            let jsonPayload = decodeURIComponent(encodeURIComponent(window.atob(base64)));
            return JSON.parse(jsonPayload);
        } catch (e) {
            console.log(e);
        }
    }
    return undefined;
};

export const DataTableColumnRender = {
    DateTime: (datetime: string | undefined) => {
        return datetime ? moment(datetime).toLocaleString() : "";
    },
    DateTime_ServerTime: (datetime: string | undefined) => {
        return datetime
            ? new Date(datetime).toLocaleTimeString("en-US", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            })
            : "";
    },
    RoleList: (current_role: string) => {
        let current_role_text = current_role.toLowerCase();
        switch (current_role_text) {
            case "administrator":
                return Object.values(ROLES_LABELS).map((x) => ({ text: x.name, value: x.id }));
            case "siteadmin":
                return Object.values(ROLES_LABELS)
                    .filter((x) => x.key !== "administrator")
                    .map((x) => ({ text: x.name, value: x.id }));
            default:
                return Object.values(ROLES_LABELS)
                    .filter((x) => x.key === "user")
                    .map((x) => ({ text: x.name, value: x.id }));
        }
    },
};

export const scientificToDecimal = (ori_value: number) => {
    const getZero = (count: number) => {
        let rVal = "";
        for (var i = 0; i < count; i++) {
            rVal += "0";
        }
        return rVal;
    };

    let tmp_value: string = ori_value.toString().replace(/\s+/g, "").replace("*10^", "e");
    let value = parseFloat(tmp_value);
    let REGEX_SCIENTIFIC = /(\d+\.?\d*)e\d*(\+|-)(\d+)/;
    let valueString = value.toString();
    let result: any = REGEX_SCIENTIFIC.exec(valueString);
    let base: string;
    let positiveNegative: string;
    let exponents: string;
    let precision: number;
    let rVal: string;

    // is scientific
    if (result) {
        // [ '1e+32', '1', '+', '2', index: 0, input: '1e+32' ]
        base = result[1];
        positiveNegative = result[2];
        exponents = result[3];

        if (positiveNegative === "+") {
            precision = parseInt(exponents);

            // base is a decimal
            if (base.indexOf(".") !== -1) {
                result = /(\d+\.)(\d)/.exec(base);

                // [ 2 ] == right side after decimal
                // [ 1 ] == left side before decimal
                precision -= result[2].length + result[1].length;

                rVal = base.split(".").join("") + getZero(precision);
            } else {
                rVal = base + getZero(precision);
            }
        } else {
            precision = base.length + parseInt(exponents) - 1;

            // if it contains a period we want to drop precision by one
            if (base.indexOf(".") !== -1) {
                precision--;
            }

            rVal = value.toFixed(precision);
        }
    } else {
        rVal = value.toString();
    }

    return rVal;
};

export const currencyRender = (value: string | number | undefined, decimalPoint: number = -1, defaultValue: any = undefined) => {
    if (value === undefined || value === "NULL") return defaultValue !== undefined ? defaultValue : "";

    let returnText = value.toString();
    if (returnText.indexOf(".") > -1) {
        let spStr: string[] = returnText.split(".");
        spStr[0] = spStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        returnText = spStr.join(".");
    } else {
        returnText = returnText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (decimalPoint !== -1) {
        if (returnText.indexOf(".") > -1) {
            if (returnText.length - (returnText.indexOf(".") + 1) < 2) {
                for (let index = returnText.length - (returnText.indexOf(".") + 1); index < 2; index++) {
                    returnText += "0";
                }

                return returnText;
            } else if (returnText.indexOf(".") + 1 < returnText.length) {
                returnText = returnText.substr(0, returnText.indexOf(".") + 3);
            }
        } else {
            returnText += ".";
            for (let index = 0; index < decimalPoint; index++) {
                returnText += "0";
            }
        }
    }

    return returnText;
};

export const DTColProps = {
    DateTime: (values: any, className: string[] = []) =>
        Object.assign(
            {},
            {
                width: "220px",
                className: `row-datetime ${className.join(" ")}`,
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime(value) : ""),
            },
            values
        ),
    DateTime_ServerTime: (values: any, className: string[] = []) =>
        Object.assign(
            {
                width: "190px",
                className: `row-datetime ${className.join(" ")}`,
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value) : ""),
            },
            values
        ),
    Currency: (values: any, className: string[] = [], decimalPoint: number = 2) => ({
        width: "220px",
        className: `row-currency ${className.join(" ")}`,
        align: "right",
        render: (value: number) => (value && value !== 0 ? currencyRender(scientificToDecimal(value), decimalPoint) : value),
        ...values,
    }),
    SCurrency: (values: any, className: string[] = [], decimalPoint: number = 2) => ({
        width: "150px",
        className: `row-currency ${className.join(" ")}`,
        align: "right",
        render: (value: number) => (value && value !== 0 ? currencyRender(scientificToDecimal(value), decimalPoint) : value),
        ...values,
    }),
    XSmall: (values: any, className: string[] = []) => ({
        width: "100px",
        className: `row-xsmall-size ${className.join(" ")}`,
        ...values,
    }),
    Small: (values: any, className: string[] = []) => ({
        width: "150px",
        className: `row-small-size ${className.join(" ")}`,
        ...values,
    }),
    Middle: (values: any, className: string[] = []) => ({
        width: "250px",
        className: `row-middle-size ${className.join(" ")}`,
        ...values,
    }),
    Large: (values: any, className: string[] = []) => ({
        width: "350px",
        className: `row-large-size ${className.join(" ")}`,
        ...values,
    }),
    XLarge: (values: any, className: string[] = []) => ({
        width: "450px",
        className: `row-xlarge-size ${className.join(" ")}`,
        ...values,
    }),
    XXLarge: (values: any, className: string[] = []) => ({
        width: "550px",
        className: `row-xxlarge-size ${className.join(" ")}`,
        ...values,
    }),
    Action: (values: any) => ({
        title: "",
        key: "Actions",
        width: "100px",
        fixed: "right",
        ...values,
    }),
};

export const ErrorMessageHandler = (pagePrefix: string, promptMessage: number, extra: any = {}): void => {
    if (promptMessage > 0) {
        //success
        message.success(`${pagePrefix} ${SUCCESS_FAILED_MSG[promptMessage]}`);
    } else {
        //fail
        message.error(`${SUCCESS_FAILED_MSG[promptMessage]} ${pagePrefix}: ${extra.message}`, 3);
    }
};