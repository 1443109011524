import { Button, Col, Dropdown, Layout, Menu, MenuProps, Row } from "antd";
import brandLogo from "../assets/vantage-full-logo-RGB-cropped.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IdcardOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { userInfo } from "../services/api";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { setSideBarCollapse } from "../store/reducers/system";
import "../index.less";
import { useEffect, useState } from "react";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { apiRequest, APIs } from "../services/api/apiConfig";
import { removeProfile, removeToken } from "../services/localStorage";

const { Content, Header, Sider } = Layout;

const AuthLayout = () => {
    const userinfo = userInfo();
    const systemState = useAppSelector((state: any) => state.system);
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [current, setCurrent] = useState<string>(location.pathname);

    const onSettingButtonClick = ({ key }: any) => {
        switch (key) {
            case "1":
                navigate("/myprofile");
                break;
            case "2":
                apiRequest(APIs.USER_LOGOUT, {})
                    .then(() => {
                        removeToken();
                        removeProfile();
                        navigate("/login", { replace: true });
                    });
                break;
            default:
                break;
        }
    };

    const onMenuClick = (e: { key: any }) => {
        switch (e.key) {
            default:
                navigate(e.key);
                setCurrent(e.key);
                break;
        }
    };

    const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
        const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    return (
        <Layout className="main-layout">
            <Sider collapsed={systemState.sideBarCollapsed} className="siderbar-container">
                <Menu
                    mode="inline"
                    theme="dark"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    onClick={onMenuClick}
                    selectedKeys={[current]}
                    items={
                        [
                            {
                                className: "logo-container",
                                title: "VIMS",
                                label: "VIMS",
                                icon: <img className="logo" alt={"VIMS"} src={brandLogo} />,
                                key: "/",
                            },
                            {
                                type: "divider",
                            },
                            {
                                key: "/clients",
                                title: "Client Management",
                                label: "Client Management",
                                icon: <IdcardOutlined />,
                            },
                            {
                                key: "/users",
                                title: "User Management",
                                label: "User Management",
                                icon: <TeamOutlined />,
                            },
                        ] as ItemType[]
                    }
                />
            </Sider>
            <Layout>
                <Header className="header shadow-effect">
                    <div className="left-panel">
                        <Button type="default" onClick={() => dispatch(setSideBarCollapse())} className="collapse-btn">
                            {systemState.sideBarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                    </div>
                    <div className="right-panel">
                        <Row>
                            <Col className="user-info-panel">
                                <span className="name-text">{userinfo?.username}</span>
                                <span>{userinfo?.type}</span>
                            </Col>
                            <Col className="setting-panel">
                                <Dropdown.Button
                                    type="link"
                                    size="large"
                                    className="setting"
                                    overlayClassName="setting-overlay-panel"
                                    menu={{
                                        onClick: onSettingButtonClick,
                                        items: [
                                            {
                                                label: "My Profile",
                                                key: "1",
                                                icon: (
                                                    <div className="topnav-profile">
                                                        <UserOutlined />
                                                    </div>
                                                ),
                                            },
                                            {
                                                label: "Logout",
                                                key: "2",
                                                icon: <PoweroffOutlined />,
                                            },
                                        ]
                                    }}
                                    placement="bottomRight"
                                    icon={<UserOutlined />}
                                />
                            </Col>
                        </Row>
                    </div>
                </Header>
                <Content className="main-content">
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AuthLayout;
