import { APIs } from "./apis";
import axiosInstance from "./axiosSetup";
import { ResponseType } from "axios";

interface ResponseProps<T> {
    status: number;
    message: string | null;
    data?: T;
}

type BaseQueryProps = {
    url: string;
    method: string;
    params?: any;
};

export const defaultBaseQueryProps: BaseQueryProps = {
    url: "",
    method: "POST",
    params: {},
};

export const apiRequest = (path: string, data: any, method: string = "POST", responseType: ResponseType = "json") => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            method: method,
            url: path,
            responseType: responseType,
            ...(method === "GET"
                ? {
                    params: data,
                }
                : { data: data || {} }),
        })
            .then((response: any) => resolve(response))
            .catch((error: any) => reject(error));
    });
};

export { APIs };
export type { ResponseProps, BaseQueryProps };
