export const ComponentType: { [key: string]: string } = {
    labelOnly: "label",
    text: "text",
    number: "number",
    numberrange: "numberrange",
    hidden: "hidden",
    password: "password",
    textarea: "textarea",
    dropdown: "dropdown",
    switch: "switch",
    checkbox: "checkbox",
    checkboxgroup: "checkboxgroup",
    radio: "radio",
    date: "date",
    time: "time",
    daterange: "daterange",
    transfer: "transfer",
    upload: "upload",
    tree: "tree",
};

export const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    PROFILE: "_UrP",
    SYSTEM_SETTING: "vims_sysCon",
};

export enum CALLBACK_KEY {
    CREATE_NEW = 1,
    CREATE_NEW_FORM_SUBMIT = 2,
    DO_EDIT = 3,
    EDIT_FORM_SUBMIT = 4,
    DO_DELETE = 5,
    DELETE_FORM_SUBMIT = 6,
    FILTER_FORM_SUBMIT = 7,
    FILTER_FORM_SUBMIT_FAILED = 8,
    COLLAPSE = 9,
    CUSTOM_ROW_OPTION_CALLBACK = 10,
    CUSTOM_MODAL_ACTION = 11,
    CUSTOM_PANEL_OPTION_ACTION = 12,
    CUSTOM_PANEL_OPTION_SUBMIT = 13,
    VIEW_RECORD = 14,
    REFRESH = 15,
    HANDLE_PAGINATION_SORTING = 16,
    ROW_SELECTION_CALLBACK = 17,
    RESYNC_JOB_SCHEDULER = 18,
    EXPANDABLE_TABLE = 19,
    REFRESH_RATE_CHANGED = 20,
    OTHERS = 21,
    BACK_TO_PREVIOUS = 22,
    EXPORT_CSV_EXCEL = 23,
};

export const ROLES_LABELS = {
    Administrator: {
        id: 1,
        key: "administrator",
        name: "Administrator",
    },
    SiteAdmin: {
        id: 2,
        key: "siteadmin",
        name: "Site Admin",
    },
    Manager: {
        id: 3,
        key: "user",
        name: "User",
    },
};

export const STATUS_TYPE: { [key: number]: string } = {
    1: "Active",
    0: "Inactive",
};

export const USER_TYPE: { [key: number]: string } = {
    1: "Admin",
    2: "User",
};

export enum SUCCESS_FAILED {
    OTHERS = 0,
    SUCCESS_CREATE_DATA = 1,
    SUCCESS_UPDATE_DATA = 2,
    SUCCESS_DELETE_DATA = 3,
    SUCCESS_LOAD_DATA = 4,
    FAILED_CREATE_DATA = -1,
    FAILED_UPDATE_DATA = -2,
    FAILED_DELETE_DATA = -3,
    FAILED_LOAD_DATA = -4,
    FAILED_DUPLICATE_DATA = -5,
}

export const SUCCESS_FAILED_MSG: { [key: number]: string } = {
    "1": "has been created successfully!",
    "2": "has been updated successfully!",
    "3": "has been deleted successfully!",
    "4": "has been loaded successfully!",
    "-1": "Failed to create",
    "-2": "Failed to update",
    "-3": "Failed to delete",
    "-4": "Failed to load",
    "-5": "Failed to duplicate",
};

