import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Form, Button, message, Col, Row } from "antd";

import { TeamOutlined } from "@ant-design/icons";
import SitePageHeader from "../../components/PageHeader";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";
import { ClientDetails } from "../../constants/type";
import LoadingComponent from "../../components/Loading";
import { apiRequest } from "../../services/api/apiConfig";
import { APIs } from "../../services/api/apis";
import { ComponentType, SUCCESS_FAILED, USER_TYPE } from "../../constants";
import { ErrorMessageHandler } from "../../utils/Common";
import { ToOptionTypeList } from "../../utils/array";

const ClientCreatePage = () => {
    let navigate = useNavigate();

    const [data, setData] = useState<ClientDetails[]>([]);
    const [clientForm] = Form.useForm();
    const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        try {
            setIsLoadingState(true);

            apiRequest(APIs.CLIENT_CREATE, { ...values })
                .then(() => {
                    ErrorMessageHandler("New client", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/clients");
                })
                .catch((err: any) => {
                    ErrorMessageHandler("new client", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                })
                .finally(() => setIsLoadingState(false));
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    useEffect(() => {
        apiRequest(APIs.USER_GET_PROFILE, {})
            .then((response: any) => {
                let clientDetail = response as ClientDetails[];
                setData(clientDetail);
            })
            .catch((err: any) => console.log(`Error: ${err}`))
    }, []);

    return (
        <SitePageHeader
            title={"Create Client Account"}
            routes={[
                { path: "/clients", breadcrumbName: "Clients Management", icon: <TeamOutlined /> },
                { path: "", breadcrumbName: "Create Client Account" },
            ]}
            onBack={() => navigate("/clients")}
        >
            {isLoadingState ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={clientForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Client Name"
                                name="clientName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ]
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Domain"
                                name="domain"
                                extra={{
                                    type: ComponentType.text,
                                    value: ""
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default ClientCreatePage;
