import { LOCAL_STORAGE_KEYS } from "../../constants";
import { ProfileProps } from "../../constants/type";

export const setItem = (key: string, value: any) => {
    let actualValue = value;
    if (typeof value === "object") {
        actualValue = JSON.stringify(actualValue);
    }

    return new Promise((res, rej) => {
        localStorage.setItem(key, actualValue);
        res(true);
    });
};

export const getItem = (key: string, defaultValue: string | null = null): any => {
    let actualValue = localStorage.getItem(key);
    if (actualValue === null) return defaultValue;

    try {
        let jsonObj = JSON.parse(actualValue);
        return jsonObj;
    } catch { }

    return actualValue;
};

export const removeItem = (key: string) => {
    localStorage.removeItem(key);
};

export const setToken = (token: string) => {
    return new Promise((res, rej) => {
        setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
        res(token);
    });
};

export const removeToken = () => {
    return new Promise<void>((res, rej) => {
        removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        res();
    });
};

export const getToken = () => {
    return getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
};

export const setProfile = (profile: ProfileProps) => {
    return new Promise((res, rej) => {
        setItem(LOCAL_STORAGE_KEYS.PROFILE, btoa(JSON.stringify(profile)));
        res(profile);
    });
};

export const removeProfile = () => {
    return new Promise<void>((res, rej) => {
        removeItem(LOCAL_STORAGE_KEYS.PROFILE);
        res();
    });
};

export const getProfile = (): ProfileProps | undefined => {
    let up = getItem(LOCAL_STORAGE_KEYS.PROFILE);
    if (up !== null) {
        return JSON.parse(atob(up)) as ProfileProps;
    }
    return undefined;
};
