import { useEffect, useState } from "react";
import { Form, Collapse, Button, Col, Divider, Row } from "antd";
import { DownloadOutlined, FilterFilled, FilterOutlined, PlusOutlined, RedoOutlined, SyncOutlined } from "@ant-design/icons";
import "./FlexiFilter.less";
import { CollapsibleType } from "antd/lib/collapse/CollapsePanel";
import { FormComponent } from "../FormComponent";
import { Link } from "react-router-dom";
import { CALLBACK_KEY, ComponentType } from "../../constants";
import { FlexiDataColumnProps, FlexiDataTableOptionsProps } from "../../constants/type";
//import { CONFIG_DATA } from "../../services/axiosSetup";

const { Panel } = Collapse;

interface FlexiFilterProps {
    title: string | React.ReactNode;
    columns: FlexiDataColumnProps[];
    options: FlexiDataTableOptionsProps;
    enableFilter?: boolean;
    initialValues?: any;
    callback?: (type: number, data: any) => void;
    exporting?: boolean;
}

const initialDefaultProps: FlexiFilterProps = {
    title: "",
    columns: [],
    options: {},
    enableFilter: true,
    exporting: false,
};

const FlexiFilter = (props: FlexiFilterProps): JSX.Element => {
    const [filterForm] = Form.useForm();
    const [currentProps, setCurrentProps] = useState(Object.assign({}, { ...initialDefaultProps }, props));
    const componentCallback = (type: number, data: any) => currentProps.callback && currentProps.callback(type, data);
    const getExtraElement = (exporting: boolean): React.ReactNode => {
        let elements: React.ReactNode[] = [];
        let openNewLinkUrl: string = "";

        if (currentProps.options.extraButtons && currentProps.options.extraButtons.length > 0) {
            currentProps.options.extraButtons.forEach((x) => {
                elements.push(
                    <Button
                        key={`btn-ee-${Math.random()}`}
                        htmlType="button"
                        style={{ marginRight: 10, width: "auto" }}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            componentCallback(CALLBACK_KEY.OTHERS, x.value);
                        }}
                        {...(x.icon && {
                            icon: x.icon,
                        })}
                    >
                        {x.text}
                    </Button>
                );
            });
        }

        if (currentProps.options.add) {
            let createText = "Create";
            if (typeof currentProps.options.add !== "boolean") {
                if (typeof currentProps.options.add === "function") {
                    openNewLinkUrl = currentProps.options.add();
                } else {
                    createText = currentProps.options.add.text;
                }
            }
            elements.push(
                <Link to={openNewLinkUrl} key={`btn-add-${Math.random()}`}>
                    <Button
                        htmlType="button"
                        className="ft-flr-btn-add"
                        icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            componentCallback(CALLBACK_KEY.CREATE_NEW, {});
                        }}
                    >
                        {createText}
                    </Button>
                </Link>
            );
        }

        // if (currentProps.options.refresh) {
        //     elements.push(
        //         currentProps.options.refresh.timer ? (
        //             <TimerButton
        //                 key={`btn-refresh-timer-${Math.random()}`}
        //                 period={currentProps.options.refresh.refreshSecond || Number(process.env.REACT_APP_REFRESH_TIME_IN_SECOND) || 0}
        //                 enablePeriodSelection={currentProps.options.refresh.enablePeriodSelection || false}
        //                 onFinish={(type: CALLBACK_KEY, period: number) => {
        //                     componentCallback(type, { currentRefreshPeriod: period });
        //                 }}
        //             />
        //         ) : (
        //             <Button
        //                 key={`btn-refresh-${Math.random()}`}
        //                 htmlType="button"
        //                 style={{ marginLeft: 10 }}
        //                 icon={<RedoOutlined style={{ fontSize: "14px" }} />}
        //                 onClick={(event) => {
        //                     event.preventDefault();
        //                     event.stopPropagation();
        //                     componentCallback(CALLBACK_KEY.REFRESH, { currentRefreshPeriod: 0 });
        //                 }}
        //             >
        //                 Refresh
        //             </Button>
        //         )
        //     );
        // }

        if (currentProps.options.resync) {
            elements.push(
                <Button
                    key={`btn-resync-${Math.random()}`}
                    htmlType="button"
                    style={{ marginLeft: 10 }}
                    icon={<SyncOutlined style={{ fontSize: "14px" }} />}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        componentCallback(CALLBACK_KEY.RESYNC_JOB_SCHEDULER, {});
                    }}
                >
                    Resync
                </Button>
            );
        }

        if (currentProps.options.export) {
            let exportText = "Export";
            if (typeof currentProps.options.export !== "boolean") {
                exportText = currentProps.options.export.text;
            }
            elements.push(
                <Button
                    key={`btn-export-${Math.random()}`}
                    htmlType="button"
                    style={{ marginLeft: 10, width: "auto" }}
                    icon={<DownloadOutlined style={{ fontSize: "14px" }} />}
                    loading={exporting}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        componentCallback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {});
                    }}
                >
                    {exportText}
                </Button>
            );
        }

        return elements;
    };

    const PanelProps =
        currentProps.enableFilter && currentProps.columns.filter((x) => x.options?.filter).length > 0
            ? {}
            : { collapsible: "disabled" as CollapsibleType, showArrow: false };

    useEffect(() => {
        setCurrentProps((prev) => ({ ...prev, title: props.title }));
    }, [props.title]);

    useEffect(() => {
        setCurrentProps((prev) => ({ ...prev, columns: props.columns }));
    }, [props.columns]);

    useEffect(() => {
        setCurrentProps((prev) => ({ ...prev, options: props.options }));
    }, [props.options]);

    useEffect(() => {
        setCurrentProps((prev) => ({ ...prev, exporting: props.exporting }));
    }, [props.exporting]);

    let extraTableElement: any = getExtraElement(currentProps.exporting || false);
    return (
        <>
            {/* {currentProps.options.add || currentProps.title ? ( */}
            {currentProps.enableFilter ? (
                <Collapse
                    // defaultActiveKey="flexi-filter-panel"
                    bordered={false}
                    className="flexi-filter"
                    onChange={(key) => componentCallback(CALLBACK_KEY.COLLAPSE, key)}
                    expandIconPosition="start"
                    expandIcon={(panelProps) => {
                        return (
                            <Button
                                type="ghost"
                                icon={
                                    panelProps.isActive ? (
                                        <FilterOutlined style={{ fontSize: "18px" }} />
                                    ) : (
                                        <FilterFilled style={{ fontSize: "18px" }} />
                                    )
                                }
                            >
                                <span style={{ fontSize: "15px" }}>Filter</span>
                            </Button>
                        );
                    }}
                >
                    <Panel
                        {...PanelProps}
                        header={<>{currentProps.title}</>}
                        key="flexi-filter-panel"
                        extra={getExtraElement(currentProps.exporting || false)}
                    >
                        <Form
                            form={filterForm}
                            layout="vertical"
                            initialValues={currentProps.initialValues}
                            onFinish={(values) => componentCallback(CALLBACK_KEY.FILTER_FORM_SUBMIT, values)}
                            onFinishFailed={(values) => componentCallback(CALLBACK_KEY.FILTER_FORM_SUBMIT_FAILED, values)}
                        >
                            {currentProps.columns && currentProps.columns.filter((x) => x.options?.filter).length > 0 && (
                                <>
                                    <Row gutter={24} style={{ width: "100%" }}>
                                        {currentProps.columns
                                            .filter((x) => x.options?.filter)
                                            .map((x) => {
                                                let col_opt = x.options?.filter;
                                                return (
                                                    <Col key={"flt-f-c-" + x.key} span={6} style={{ marginBottom: "10px" }}>
                                                        <FormComponent
                                                            label={col_opt?.text || x.title as string || ""}
                                                            name={x.key || ""}
                                                            extra={{
                                                                type: col_opt?.type || ComponentType.text,
                                                                value: col_opt?.value,
                                                                ...col_opt,
                                                            }}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                    <Divider orientation="left" className="divider-nogap"></Divider>
                                    <Row style={{ width: "100%" }}>
                                        <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                                            <Button
                                                type="default"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    filterForm.resetFields();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </Panel>
                </Collapse>
            ) : typeof currentProps.title === "boolean" && !currentProps.title && extraTableElement.length === 0 ? (
                <></>
            ) : (
                <div className="disabled-filter-panel">
                    <div className="title">{currentProps.title || ""}</div>
                    <div className="extra">{getExtraElement(currentProps.exporting || false)}</div>
                </div>
            )}

            {/* ) : null} */}
        </>
    );
};

export default FlexiFilter;
export type { FlexiFilterProps };
