import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SystemHelper from "../../helpers/systemHelper";

export interface SystemConfigProps {
    title: string;
    isLogin: boolean;
    sideBarCollapsed: boolean;
    refreshingToken: boolean;
}

const getCurrentSystemState = () => {
    let sysConfig = new SystemHelper();

    return {
        title: sysConfig._systemConfig.title,
        isLogin: sysConfig._systemConfig.isLogin,
        sideBarCollapsed: sysConfig._systemConfig.sideBarCollapsed,
        refreshingToken: sysConfig._systemConfig.refreshingToken,
    };
};

export const systemConfigSlice = createSlice({
    name: "systemConfig",
    initialState: Object.assign({}, getCurrentSystemState()),
    reducers: {
        login: (state) => {
            state.isLogin = true;

            let sysConfig = new SystemHelper();
            sysConfig.setIsLogin(true);
        },
        logout: (state) => {
            state.isLogin = false;

            let sysConfig = new SystemHelper();
            sysConfig.setIsLogin(false);
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;

            let sysConfig = new SystemHelper();
            sysConfig.setTitle(action.payload);
        },
        setSideBarCollapse: (state) => {
            let sysConfig = new SystemHelper();
            sysConfig.setSideBarCollapse(!state.sideBarCollapsed);

            state.sideBarCollapsed = !state.sideBarCollapsed;
        },
        setRefreshToken: (state, action: PayloadAction<boolean>) => {
            state.refreshingToken = action.payload;
        },
    },
});

export const { login, logout, setTitle, setSideBarCollapse, setRefreshToken } =
    systemConfigSlice.actions;

export default systemConfigSlice.reducer;
