import { useEffect, useState } from "react";

import bg_image from "../../assets/vantage-zoom-bg-C_removed.png";
import logo_jpg from "../../assets/vantage-full-logo-RGB-teal.png";

import { Button, Form, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./LoginPage.less";

import { useNavigate } from "react-router-dom";
import { parseJwt } from "../../utils/Common";
import { FormComponent } from "../../components/FormComponent";
import { ComponentType } from "../../constants";
import { INVALID_EMAIL_FORMAT, REQUIRED_FIELD } from "../../constants/errorMessge";
import { apiRequest, APIs } from "../../services/api/apiConfig";
import { publicIpv4 } from "public-ip";
import { setProfile, setToken } from "../../services/localStorage";

function LoginPage() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ipAddress, setIpAddress] = useState<string>("");

    let navigate = useNavigate();

    useEffect(() => {
        publicIpv4().then((res: any) => setIpAddress(res));
    }, []);

    const loginSuccess = (accessToken: string) => {
        message.success("Login success");
        const userObj = parseJwt(accessToken);

        if (userObj) {
            navigate("/clients");
        }
    };

    const onSubmit = (values: any) => {
        setIsLoading(true);

        apiRequest(APIs.USER_LOGIN, {
            username: values.userName,
            password: values.password,
            ip_address: ipAddress
        })
            .then(async (res: any) => {
                const accessToken = res.token;

                await setToken(accessToken);

                // await setProfile({
                //     username: res.user.username,
                //     firstName: res.user.firstName,
                //     lastName: res.user.lastName,
                //     roleIds: res.user.roleIds,
                //     roles: res.user.roles,
                // });

                window.location.pathname = "/clients";
            })
            .catch((res: any) => message.error("Failed to Login: " + res.message, 2))
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="login-container">
            <img src={bg_image} alt="" className="background-image" />
            <div className="login-form">
                <img alt={"logo"} src={logo_jpg} style={{ height: "100px" }} />
                <Form layout="vertical" onFinish={onSubmit}>
                    <FormComponent
                        label=""
                        name={"userName"}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            inputProps: {
                                prefix: <UserOutlined style={{ color: "#C4C4C4" }} />,
                            },
                            rules: [
                                { required: true, message: REQUIRED_FIELD },
                                { type: "email", message: INVALID_EMAIL_FORMAT },
                            ],
                        }}
                    />
                    <FormComponent
                        label=""
                        name={"password"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            inputProps: {
                                prefix: <LockOutlined style={{ color: "#C4C4C4" }} />,
                            },
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                    <Button type="primary" htmlType="submit" style={{ width: "100%", margin: "20px 0px" }} loading={isLoading}>
                        Log In
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default LoginPage;
