import { LOCAL_STORAGE_KEYS } from "../constants";
import { getItem, setItem } from "../services/localStorage";
import { SystemConfigProps } from "../store/reducers/system";

class SystemHelper {
    public _systemConfig: SystemConfigProps = {
        title: "",
        isLogin: false,
        sideBarCollapsed: false,
        refreshingToken: false,
    };

    constructor() {
        let sysConfig = getItem(LOCAL_STORAGE_KEYS.SYSTEM_SETTING);
        if (sysConfig !== null) {
            this._systemConfig = sysConfig as SystemConfigProps;
        }
    }

    getSystemConfig = () => this._systemConfig;

    setTitle = (title: string) => {
        this._systemConfig = { ...this._systemConfig, title: title };
        this.save_systemConfig();
    };

    setIsLogin = (isLogin: boolean) => {
        this._systemConfig = { ...this._systemConfig, isLogin: isLogin };
        this.save_systemConfig();
    };

    setSideBarCollapse = (isCollapsed: boolean) => {
        this._systemConfig = { ...this._systemConfig, sideBarCollapsed: isCollapsed };
        this.save_systemConfig();
    };

    save_systemConfig() {
        setItem(LOCAL_STORAGE_KEYS.SYSTEM_SETTING, this._systemConfig);
    }
}

export default SystemHelper;
