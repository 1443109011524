import { Image } from "antd";
import ComingSoonImage from "../../assets/coming__soon_cropped.jpg";

const ComingSoonComponent = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ textAlign: "center", width: "55%", paddingTop: "50px", paddingBottom: "50px" }}>
                <Image src={ComingSoonImage} preview={false} />
            </div>
        </div>
    );
};

export default ComingSoonComponent;
