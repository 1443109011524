import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Form, Button, message, Col, Row } from "antd";
import { ClientDetails } from "../../constants/type";
import SitePageHeader from "../../components/PageHeader";
import { HomeOutlined } from "@ant-design/icons";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";
import LoadingComponent from "../../components/Loading";
import { apiRequest } from "../../services/api/apiConfig";
import { APIs } from "../../services/api/apis";
import { ComponentType, SUCCESS_FAILED } from "../../constants";
import { ErrorMessageHandler } from "../../utils/Common";

const ClientEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let selectedClient = location.state as ClientDetails;

    const [clientForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comData] = useState<ClientDetails>(selectedClient);
    const [, setData] = useState<ClientDetails[]>([]);

    const onSubmit = (values: any) => {
        try {
            setIsLoading(true);

            apiRequest(APIs.CLIENT_UPDATE, {
                active: values.active,
                id: values.id,
                clientName: values.clientName,
                domain: values.domain
            })
                .then(() => {
                    ErrorMessageHandler("Client data", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/clients");
                })
                .catch((err) => {
                    ErrorMessageHandler("client detail", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                })
                .finally(() => setIsLoading(false));
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    useEffect(() => {
        apiRequest(APIs.CLIENT_GET, { id: comData.id })
            .then((response: any) => {
                let clientDetail = response as ClientDetails[];
                setData(clientDetail);
            })
            .catch((err) => console.log(`Error: ${err}`));
    }, []);

    return (
        <SitePageHeader
            title={"Edit Client Account"}
            routes={[
                { path: "/clients", breadcrumbName: "Clients Management", icon: <HomeOutlined /> },
                { path: "", breadcrumbName: "Edit Client Account" },
            ]}
            onBack={() => navigate("/clients")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={clientForm} layout="horizontal" initialValues={comData} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Client Name"
                                name="clientName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Client Key"
                                name="clientKey"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: {
                                        tooltip: "Changes made here will not be saved."
                                    },
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Domain"
                                name="domain"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Status"
                                name={"active"}
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default ClientEditPage;
