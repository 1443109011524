export const APIs = {
    USER_LOGIN: "/bo-api/myaccount/login",
    GET_MY_PROFILE_DATA: "/bo-api/myaccount/profile",
    USER_LOGOUT: "/bo-api/myaccount/logout",
    RESET_MY_PASSWORD: "/bo-api/myaccount/change-pwd",

    USER_GET_PROFILE: "/bo-api/user-mgmt/profile/get",
    USER_CREATE_PROFILE: "/bo-api/user-mgmt/profile/create",
    USER_UPDATE_PROFILE: "/bo-api/user-mgmt/profile/update",
    USER_DELETE_PROFILE: "/bo-api/user-mgmt/profile/delete",
    USER_UPDATE_PASSWORD: "/bo-api/user-mgmt/profile/update-pwd",
    USER_RESET_PASSWORD: "/bo-api/user-mgmt/profile/reset-pwd",

    CLIENT_GET: "/bo-api/client-mgmt/client/get",
    CLIENT_CREATE: "/bo-api/client-mgmt/client/create",
    CLIENT_UPDATE: "/bo-api/client-mgmt/client/update",
    CLIENT_DELETE: "/bo-api/client-mgmt/client/delete",

};