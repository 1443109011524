import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Form, Button, message, Col, Row } from "antd";
import { UserDetails } from "../../constants/type";
import SitePageHeader from "../../components/PageHeader";
import { HomeOutlined } from "@ant-design/icons";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessge";
import LoadingComponent from "../../components/Loading";
import { apiRequest } from "../../services/api/apiConfig";
import { APIs } from "../../services/api/apis";
import { ComponentType, SUCCESS_FAILED, USER_TYPE } from "../../constants";
import { ErrorMessageHandler } from "../../utils/Common";
import { ToOptionTypeList } from "../../utils/array";

const UserEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let selectedUser = location.state as UserDetails;

    const [userForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comData] = useState<UserDetails>(selectedUser);
    const [, setData] = useState<UserDetails[]>([]);

    const onSubmit = (values: any) => {
        try {
            setIsLoading(true);

            let newObj = {
                active: values.active,
                description: values.description,
                first_name: values.first_name,
                last_name: values.last_name,
                nickname: values.nickname,
                type: values.type,
                username: values.username,
                selected_uuid: values.uuid,
            };

            apiRequest(APIs.USER_UPDATE_PROFILE, { ...newObj })
                .then(() => {
                    ErrorMessageHandler("User data", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/users");
                })
                .catch((err) => {
                    ErrorMessageHandler("user detail", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                })
                .finally(() => setIsLoading(false));
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    useEffect(() => {
        apiRequest(APIs.USER_GET_PROFILE, { uuid: comData.uuid })
            .then((response: any) => {
                let userDetail = response as UserDetails[];
                setData(userDetail);
            })
            .catch((err) => console.log(`Error: ${err}`));
    }, []);

    return (
        <SitePageHeader
            title={"Edit User Account"}
            routes={[
                { path: "/users", breadcrumbName: "Users Management", icon: <HomeOutlined /> },
                { path: "", breadcrumbName: "Edit User Account" },
            ]}
            onBack={() => navigate("/users")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={userForm} layout="horizontal" initialValues={comData} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Username"
                                name="username"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="UUID"
                                name="uuid"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="First Name"
                                name="first_name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last Name"
                                name="last_name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="User Type"
                                name="type"
                                extra={{
                                    type: ComponentType.dropdown,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                    value: ToOptionTypeList(USER_TYPE),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Nickname"
                                name="nickname"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Email"
                                name="email"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Status"
                                name={"active"}
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last IP Address"
                                name="last_ip_address"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last Login At"
                                name="last_login_utc"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Created By"
                                name="created_by"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Created Date"
                                name="created_date_utc"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Modified By"
                                name="modified_by"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Modified Date"
                                name="modified_date_utc"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    disabled: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default UserEditPage;
