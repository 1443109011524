import { message } from "antd";
import axios from "axios";
import { userLogout } from "../api";
import { ResponseProps } from "./apiConfig";
import { APIs } from "./apis";
import { getToken } from "../localStorage";

let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    (config: any) => {
        const token = getToken();
        //const source = getSource("");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        // if (source) {
        //     config.headers["Source"] = `${source}`;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (res) => {
        if (res.status === 200) {
            if (res.headers["content-type"] === "application/zip") {
                return Promise.resolve({ data: res.data, headers: res.headers });
            }

            const responseData: ResponseProps<any> = res.data;

            if (responseData.status === 0) {
                return Promise.resolve(responseData.data);
            } else {
                return Promise.reject({
                    status: responseData.status,
                    message: responseData.message,
                    data: responseData.data ? responseData.data : {},
                } as ResponseProps<null>);
            }
        }
        return Promise.reject({
            status: 404,
            message: "API calling fail!!",
        } as ResponseProps<null>);
    },
    async (err) => {
        const oriConfig = err.config;
        if (oriConfig.url !== APIs.USER_LOGIN && err.response) {
            if (err.response.status === 401) {
                let data: any = (oriConfig.method as string).toLowerCase() === "post" ? JSON.parse(oriConfig.data) : oriConfig.params;
                if (data?.manualCatch) {
                    return Promise.reject(err);
                } else {
                    message.error("Token expired. Please login again.");
                    userLogout().then((res: any) => {
                        setTimeout(() => {
                            window.location.href = "/login";
                        }, 2500);
                    });
                    return Promise.reject(err);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;
