import { Route, Routes } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import Dashboard from "../pages/Dashboard";
import LoginPage from "../pages/LoginPage";
import MyAccount from "../pages/MyAccount";
import UserCreatePage from "../pages/Users/UserCreatePage";
import UserEditPage from "../pages/Users/UserEditPage";
import UserManagement from "../pages/Users/UserManagement";
import ClientCreatePage from "../pages/Clients/ClientCreatePage";
import ClientEditPage from "../pages/Clients/ClientEditPage";
import ClientManagement from "../pages/Clients/ClientManagement";
import { userInfo } from "../services/api";

export const AppRoute = () => {
    const isLoggedIn = userInfo();

    return (
        <Routes>
            {isLoggedIn ? (
                <>
                    <Route path="/" element={<AuthLayout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="/users" element={<UserManagement />} />
                        <Route path="/users/create" element={<UserCreatePage />} />
                        <Route path="/users/edit" element={<UserEditPage />} />
                        <Route path="/clients" element={<ClientManagement />} />
                        <Route path="/clients/create" element={<ClientCreatePage />} />
                        <Route path="/clients/edit" element={<ClientEditPage />} />
                        <Route path="/myprofile" element={<MyAccount />} />
                    </Route>
                </>
            ) : (
                <>
                    <Route path="*" element={<LoginPage />} />
                </>
            )}
            <Route path="/login" element={<LoginPage />} />

        </Routes>
    );
};

export default AppRoute;